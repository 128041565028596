<template>
  <div class="l-wrapper">
    <b-form-group>
      <b-form-input
        name="name"
        :value="contactData.name"
        @input="update('name', $event)"
        :placeholder="$t('Name') + '*'"
        v-validate="{ required: true }"
        :state="validateState('name')"
        aria-describedby="name-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="name-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="address"
        :value="contactData.address"
        @input="update('address', $event)"
        :placeholder="$t('Address') + '*'"
        v-validate="{ required: true }"
        :state="validateState('address')"
        aria-describedby="address-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="address-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="contactPerson"
        :value="contactData.contactPerson"
        @input="update('contactPerson', $event)"
        :placeholder="$t('Contact') + '*'"
        v-validate="{ required: true }"
        :state="validateState('contactPerson')"
        aria-describedby="contactPerson-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="contactPerson-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="email"
        :value="contactData.email"
        @input="update('email', $event)"
        :placeholder="$t('E-mail') + '*'"
        type="email"
        v-validate="{ email: true, required: true }"
        :state="validateState('email')"
        aria-describedby="email-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="email-live-feedback">
        {{ $t('This is an invalid e-mail') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="phone"
        :value="contactData.phone"
        @input="update('phone', $event)"
        :placeholder="$t('Phone number') + '*'"
        v-validate="{ required: true }"
        :state="validateState('phone')"
        aria-describedby="phone-live-feedback"
        trim
      ></b-form-input>
      <b-form-invalid-feedback id="phone-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="department"
        :value="contactData.department"
        @input="update('department', $event)"
        :placeholder="$t('Department')"
        :state="validateState('department')"
        aria-describedby="department-live-feedback"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="orderNumber"
        :value="contactData.orderNumber"
        @input="update('orderNumber', $event)"
        :placeholder="$t('OrderNumber') + '*'"
        v-validate="{ required: true }"
        :state="validateState('orderNumber')"
        aria-describedby="orderNumber-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="orderNumber-live-feedback">
        {{ $t('This is a required field') }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script lang="js">
import ValidateState from '@/mixin/validateState';

export default {
  name: 'ClientContactData',
  mixins: [
    ValidateState,
  ],
  model: {
    prop: 'contactData',
  },
  props: {
    contactData: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.contactData, [key]: value });
    },
  },
  inject: ['$validator'],
};
</script>

<style lang="scss">

</style>
