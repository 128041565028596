import Vue from 'vue';
import Router from 'vue-router';
import Intro from './views/Intro.vue';
import Return from './views/Return.vue';
import Confirmation from './views/Confirmation.vue';
import EmptyRouterView from './views/EmptyRouterView.vue';
import i18n from './i18n';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/$i18n.locale',
    },
    {
      path: '/:lang',
      component: EmptyRouterView,
      beforeEnter(to, from, next) {
        // use the language from the routing param or default language
        let language = to.params.lang;
        if (!language) {
          language = 'nl';
        }

        if (!['nl', 'fr', 'en'].includes(language)) return next('nl');

        // set the current language for vuex-i18n. note that translation data
        // for the language might need to be loaded first
        if (i18n.locale !== language) {
          i18n.locale = language;
        }

        return next();
      },
      children: [
        {
          path: '',
          name: 'intro',
          component: Intro,
        },
        {
          path: 'return',
          name: 'return',
          component: Return,
        },
        {
          path: 'confirmation',
          name: 'confirmation',
          component: Confirmation,
        },
      ],
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   // use the language from the routing param or default language
//   let language = to.params.lang;
//   if (!language) {
//     language = 'nl';
//   }
//
//   if (!['nl', 'fr'].includes(language)) return next('nl');
//
//   // set the current language for vuex-i18n. note that translation data
//   // for the language might need to be loaded first
//   if (i18n.locale !== language) {
//     i18n.locale = language;
//   }
//   return next();
// });

export default router;
