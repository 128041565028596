<template>
 <div class="l-wrapper">

  <b-form-group>
    <b-form-input
      name="type"
      :value="product.type"
      @input="update('type', $event)"
      :placeholder="$t('TypeOfDevice') + '*'"
      v-validate="{ required: true }"
      :state="validateState('type')"
      aria-describedby="type-live-feedback"
    ></b-form-input>
    <b-form-invalid-feedback id="type-live-feedback">
      {{ $t('This is a required field') }}
    </b-form-invalid-feedback>
  </b-form-group>

  <b-form-group>
    <b-form-input
      name="serialNumber"
      :value="product.serialNumber"
      @input="update('serialNumber', $event)"
      :placeholder="$t('SerialNumber') + '*'"
      v-validate="{ required: true }"
      :state="validateState('serialNumber')"
      aria-describedby="serialNumber-live-feedback"
    ></b-form-input>
    <b-form-invalid-feedback id="serialNumber-live-feedback">
      {{ $t('This is a required field') }}
    </b-form-invalid-feedback>
  </b-form-group>

  <b-form-group :label="$t('ReasonReparation')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="type.selected"
        :options="type.options"
        @input="update('reason', $event)"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

   <b-form-group :label="$t('Quote')" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="quote.selected"
        :options="quote.options"
        @input="update('quote', $event)"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>
  </div>
</template>
<script>
import ValidateState from '@/mixin/validateState';
import i18n from '../i18n';

export default {
  name: 'ProductData',
  data() {
    return {
      type: {
        selected: 'reparation',
        options: [
          { text: i18n.tc('ReasonOptions.Reparation'), value: 'reparation' },
          { text: i18n.tc('ReasonOptions.Maintenance'), value: 'maintenance' },
          { text: i18n.tc('ReasonOptions.Discontinuation'), value: 'discontinuation' },
        ],
      },
      quote: {
        selected: 'no',
        options: [
          { text: i18n.tc('Yes'), value: 'yes' },
          { text: i18n.tc('No'), value: 'no' },
        ],
      },
    };
  },
  mixins: [
    ValidateState,
  ],
  model: {
    prop: 'product',
  },
  props: {
    product: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.product, [key]: value });
    },
  },
};
</script>

<style lang="scss">
  .c-product-grid {
    $self: &;

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.3rem;

      &--header {
        #{ $self }__item {
          color: white;
          background: $blue;

          &--empty {
            background: none;
          }
        }
      }
    }

    &__item {
      flex-grow: 1;
      width: calc(95% / 7);
      margin-right: 0.3rem;
      padding: 0.2rem 0.3rem;
      font-size: 0.75rem;
      background: $grey-light;
      word-break: break-word;

      &:last-child {
        margin-right: 0;
        width: 5%;
      }

      &--empty {
        background: none;
      }
    }
  }
  .c-product-data__add {
    .wrapper {
      display: flex;
      align-items: center;
    }
    .description {
      color: $orange;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
  }
</style>
