<template>
  <div class="l-wrapper">
    <div class="full-width l-col">
      <b-form-group>
        <b-form-textarea
          name="description"
          id="textarea"
          :value="explanation.text"
          @input="update('text', $event)"
          :placeholder="$t('Description')"
          rows="10"
          max-rows="10"
        ></b-form-textarea>
      </b-form-group>
    </div>
  </div>
</template>

<script lang="js">

import ValidateState from '@/mixin/validateState';

export default {
  name: 'Explanation',
  inject: ['$validator'],
  mixins: [
    ValidateState,
  ],
  model: {
    prop: 'explanation',
  },
  props: {
    explanation: Object,
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.explanation, [key]: value });
    },
  },
};
</script>

<style lang="scss">

</style>
