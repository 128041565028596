<!-- eslint-disable max-len -->
<template>
  <div class="c-language-switcher">
        <span
        v-for="(locale, i) in ['fr', 'en', 'nl']" :key="`language-${i}`" :value="locale"> &nbsp;
        <a v-bind:class = "(language === locale)?'item --active':'item'" :value="locale" :href="'/' + locale">{{ locale }}</a>
        </span>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  name: 'LanguageSwitcher',
  computed: {
    language() {
      if (i18n.locale === 'nl') {
        return 'nl';
      }
      if (i18n.locale === 'en') {
        return 'en';
      }
      return 'fr';
    },
  },
};
</script>

<style lang="scss">
  .c-language-switcher {
    .item {
      font-size: 1.2rem;
      color: white;
      padding-bottom: 3px;
      text-decoration: none;
      text-transform: uppercase;

      &:hover{
        text-decoration: none;
        border-bottom: 1px solid white;
        border-bottom-width: 5px;
        padding-bottom: 3px;

        transition: border-bottom-width 0.3s;
      }
    }
    .--active {
      border-bottom: 1px solid white;
      border-bottom-width: 5px;

    }
  }
</style>
