import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    submission: {
      acceptTerms: false,
      clientContactData: {
        name: '',
        address: '',
        contactPerson: '',
        email: '',
        phone: '',
        department: '',
        orderNumber: '',
      },
      explanation: {
        text: '',
      },
      product:
        {
          type: '',
          serialNumber: '',
          reason: 'reparation',
          quote: 'no',
        },
    },
  },
  mutations: {
    updateSubmission(state, submission) {
      Object.assign(state.submission, submission);
    },
    updateProduct(state, payload) {
      state.submission.product = payload.product;
    },
    cleanData(state, payload) {
      _.forEach(state.submission.explanation, (item: any, key: any) => {
        // @ts-ignore
        state.submission.explanation[key] = null;
      });

      const product = state.submission.product;
      _.forEach(product, (item: any, key: any) => {
        // @ts-ignore
        state.submission.product = {
          type: '',
          serialNumber: '',
          reason: 'reparation',
          quote: 'no',
        };
      });
    },
  },
  actions: {
    cleanData(context) {
      context.commit('cleanData');
    },
  },
});
