import * as emailjs from 'emailjs-com';

class MailService {
  private readonly serviceId: string;

  private readonly userId: string;

  constructor() {
    this.userId = 'user_NHjVs5XX06aBqRn456NHI';
    this.serviceId = 'service_6p5n078';

    emailjs.init(this.userId);
  }

  sendSubmissionMail(params: any) {
    const templateParams = {
      clientContactData: params.clientContactData,
      explanation: params.explanation,
      declaration: params.declaration,
      product: params.product,
    };

    this.send('Fres_kab_reparation_form', templateParams);
  }

  send(templateId: string, templateParams: Record<string, unknown>) {
    return emailjs.send(this.serviceId, templateId, templateParams);
  }
}
export default new MailService();
